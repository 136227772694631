<template>
    <div>
        <v-toolbar dense color="primary" dark>
            <v-toolbar-title>Datos generales</v-toolbar-title>
        </v-toolbar>
        <v-row>


            <v-col cols="12" md="6">
                <v-card class="ma-1">
                    <v-card-title class="headline grey lighten-2 mb-3" primary-title>
                        Demográficos
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" md="6" v-for="(itm1, idp) in personales" :key="idp">
                                <v-text-field
                                    :label="itm1.label"
                                    v-model="dp[itm1.value]"
                                    dense
                                    readonly
                                />
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>



            <v-col cols="12" md="6">
                <v-card class="ma-1">
                    <v-card-title class="headline grey lighten-2 mb-3" primary-title>
                        Domicilio
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" md="6" v-for="(itm1, idp) in domicilio" :key="idp">
                                <v-text-field
                                    :label="itm1.label"
                                    v-model="dp[itm1.value]"
                                    dense
                                    readonly
                                />
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="6">
                <v-card class="ma-1">
                    <v-card-title class="headline grey lighten-2 mb-3" primary-title>
                        Observaciones
                    </v-card-title>
                    <v-card-text>
                        <div v-html="dp.observaciones">
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="6">
                <v-card class="ma-1">
                    <v-card-title class="headline grey lighten-2 mb-3" primary-title>
                        Actividad
                    </v-card-title>
                    <v-card-text>
                        <div v-html="dp.actividad">
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>

        </v-row>
    </div>
</template>

<script>
export default {
    props:{
        dp:Object,
    },
    data:()=>({
        personales:[
            {label:'Estado civil', value:'estadoCivilNom'},
            {label:'Grupo sanguineo', value:'demograficosTipoSangre'},
            {label:'¿Es indígena?', value:'etnicoNom'},
            {label:'¿Habla español?', value:'espaniolNom'},
            {label:'Lengua indígena', value:'demograficosOrigenEtnicoNom'},
            {label:'Religión', value:'demiograficosReligionNom'},
            {label:'Sabe leer y escribir', value:'leerEscribirNom'},
            {label:'Nivel educativo completo', value:'escolaridadCompletaNom'},
            {label:'Escolaridad', value:'escolaridadNom'},
            {label:'Alergias', value:'alergias'},
        ],
        domicilio:[
            {label:'Calle', value:'vialidad'},
            {label:'No. Exterior', value:'noExterior'},
            {label:'No. Interior', value:'noInterior'},
            {label:'Localidad', value:'localidadNom'},
            {label:'Municipio', value:'municipioNom'},
            {label:'Entidad', value:'estadoNom'},
            {label:'Colonia', value:'colonia'},
            {label:'Código postal', value:'codigoPostalCodigo'},
        ]
    }),

}
</script>

<style>

</style>